@import "../../utils/scss/index.scss";

@mixin btn-style {
  color: white;
  font-size: 1.3rem;
  font-weight: normal;
  font-weight: 600;
  position: relative;
  max-width: 360px;
  background-color: $bright-blue;
  padding: 15px 0;
  width: 90%;
  border-radius: 30px;
  margin-bottom: 15px;
  border: 2px solid transparent;
  box-sizing: border-box;
  outline: none;
  & + .results__btn {
    background: none;
    border-color: $white;
  }
}

.results {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background: $dark-blue;
  background: linear-gradient($dark-blue, $light-blue);

  &__top {
    position: relative;
    max-width: 80%;
    @include for-size(desktop) {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-top: 67px;
      margin-bottom: 30px;
    }
    & > div {
      margin-top: 36px;
      @include for-size(desktop) {
        margin-top: 0;
        width: 30%;
        display: inline-block;
        padding: 0;
      }
      svg {
        height: 100px;
        @include for-size(tablet) {
          height: 120px;
        }
      }
    }

    &__text {
      &-a {
        display: block;
        padding-bottom: 10px;
        font-size: 1.5rem;
        line-height: 1.9rem;
        margin-top: 25px;
        @include for-size(tablet) {
          font-size: 2rem;
          line-height: 2.8125rem;
          margin-top: 25px;
        }
        @include for-size(desktop) {
          display: inline-block;
          width: 40%;
        }
      }
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80%;
      // border: 1px solid white;

      &__btn-container {
        margin-bottom: 35px;
        margin-top: 15px;
      }

      &__recommendations {
        display: flex;
        flex-direction: column;
        padding: 40px 0 0;
        width: 85%;
        @include for-size(tablet) {
          padding-bottom: 63px;
        }
        @include for-size(desktop) {
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          padding-bottom: 43px;
        }
      }

      &__movie-recommendations {
        padding-bottom: 15px;
        // width: 100%;
        @include for-size(desktop) {
          padding-bottom: 130px;
        }
      }

      &__footer {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        @include for-size(desktop) {
          padding-top: 88px;
        }

        strong {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          display: block;
          color: #ccc;
        }
        &__links {
          margin-bottom: 20px;

          a {
            font-size: 12px;
            display: block;
            padding-right: 10px;
            line-height: 16px;
            color: #ccc;
          }
        }
        &__share {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;

          &__line {
            width: 2px;
            height: 100%;
            background-color: $light-blue;
            border-radius: 1px;
          }

          &__line__image {
            width: 2px;
            height: 100%;
            background-color: $light-blue;
            border-radius: 1px;
          }

          &__item {
            height: 100%;
            position: relative;
            padding: 0 4vw;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              cursor: pointer;
            }

            &:before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              top: 0;
              right: 0;
              background-color: white;
            }

            &:nth-child(4) {
              padding: 0 0 0 20px;

              &:before {
                display: none;
              }
            }

            &__last {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }

    &__text {
      &-a {
        width: 85%;
        margin: 0 auto;
        display: block;
        padding-bottom: 29px;
        font-size: 1.5rem;
        line-height: 1.5rem;
        @include for-size(tablet) {
          font-size: 2rem;
          line-height: 2.9375rem;
        }
        @include for-size(desktop) {
          font-size: 2.5rem;
          margin-bottom: 44px;
        }
      }
    }

    &__rec {
      height: 210px;
      margin-bottom: 35px;
      width: 100%;
      position: relative;
      display: flex;
      // align-items: center;
      @include for-size(desktop) {
        width: 32%;
        height: 250px;
      }

      &__frame {
        position: absolute;
        border-radius: 25px;
        // background-color: $bright-blue;
        background: linear-gradient(
          0deg,
          rgba(1, 108, 249, 1) 50%,
          rgba(1, 61, 139, 1) 100%
        );
        height: 100%;
        width: 100%;
        z-index: 0;
      }

      &__trait {
        position: absolute;
        top: -13px;
        left: -10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 58px;
        width: 58px;
        background-color: $white;
        border-radius: 50%;
        z-index: 2;
        box-shadow: 0px 11px 18px rgba(0, 0, 0, 0.1);
        @include for-size(tablet) {
          height: 67px;
          width: 67px;
          top: -10px;
          left: -10px;
          img {
            height: 33px;
          }
        }

        > span {
          position: absolute;
          left: 70px;
          top: 30px;
          font-size: 0.9rem;
          font-weight: 800;
          white-space: nowrap;
          @include for-size(tablet) {
            left: 92px;
          }

          @include for-size(desktop) {
            left: 80px;
          }
        }
      }

      > img {
        height: 100%;
        object-fit: contain;
        z-index: 1;
        position: absolute;
        right: 0;
        transform: scale(1.1);
        transform-origin: bottom left;
      }

      &__text {
        width: 52%;
        height: calc(100% - 45px);
        text-align: left;
        z-index: 2;
        padding-left: 15px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        overflow: hidden;
        @include for-size(tablet) {
          height: 100%;
          padding-left: 82px;
          padding-top: 0;
        }
        @include for-size(desktop) {
          padding-left: 20px;
          padding-top: 35px;
          width: 45%;
        }
      }
      &__inner {
        max-height: 90%;
        padding-top: 40px;

        @include for-size(tablet) {
          padding-top: 50px;
        }
        @include for-size(desktop) {
          padding-top: 40px;
        }
        &__name {
          display: block;
          padding-bottom: 10px;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.5rem;
          @include for-size(desktop) {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }
        &__phrase {
          font-size: 0.76rem;
          line-height: 1rem;
          overflow: hidden;
          max-height: 80%;
          display: block;
          padding-right: 37px;

          @include for-size(tablet) {
            font-size: 1.25rem;
            line-height: 1.5625rem;
            padding-right: 0;
          }
          @include for-size(desktop) {
            font-size: 0.9rem;
            line-height: 1.125rem;
          }
        }
      }
    }

    &__movie-rec {
      color: white;

      &__static {
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        .results__main__movie-rec {
          width: 30%;
          @include for-size(desktop) {
            width: 32%;
          }
        }
      }

      &__frame {
        position: relative;
        background-color: white;
        border-radius: 25px;
        color: black !important;
        // height: 260px;
        // width: 330px;
        overflow: hidden;
        > img {
          height: 75%;
          width: 100%;
          object-fit: contain;
          position: relative;
          // border-radius: 25px;
          top: 0;
        }
      }
      &__title {
        margin: 28px 0 12px;
        height: 50px;
        display: block;
        text-align: left;
        line-height: 1.8rem;
        @include for-size(tablet) {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }
      }
      &__footer {
        height: 70px;
        @include for-size(tablet) {
          height: 120px;
        }
      }
      &__name {
        margin: 10px 0;
        display: block;
        text-align: left;
        font-size: 1.2rem;
        font-weight: bold;
        padding-left: 10px;
        @include for-size(tablet) {
          font-size: 1.75rem;
          line-height: 1.5rem;
          padding: 20px;
        }
        @include for-size(desktop) {
          font-size: 1.75rem;
          line-height: 1.8rem;
          padding: 12px 22px;
        }
      }
      &__trait {
        position: absolute;
        bottom: 10px;
        left: 10px;
        height: 25px;
        width: 25px !important;
        @include for-size(tablet) {
          bottom: 20px;
          left: 20px;
        }
      }
    }

    &__canvas {
      width: 100%;
      height: 100%;
      position: relative;
      margin: 0 auto;

      > canvas {
        width: 100%;
        height: 100%;
        border-radius: 25px 25px 0 0;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        touch-action: none;
      }

      &-wrapper {
        background: $white;
        width: 85%;
        margin: 0 auto;
        border-radius: 25px;
        padding-bottom: 20px;
        @include for-size(tablet) {
          margin-bottom: 25px;
        }
        @include for-size(desktop) {
          margin-bottom: 0;
          width: 90%;
        }
        p {
          color: $black;
          font-style: normal;
          font-weight: 600;
          font-size: 1.0625rem;
          line-height: 1.1875rem;
          letter-spacing: -0.5px;
          text-align: left;
          margin: 19px 16px;
          @include for-size(tablet) {
            font-size: 1.5625rem;
            line-height: 1.75rem;
            width: 45%;
          }
          @include for-size(desktop) {
            margin: 68px 51px;
            font-size: 2.5rem;
            line-height: 2.75rem;
            width: 40%;
          }
        }
      }
    }

    &__disney-logo {
      width: 50%;
      position: relative;
      display: block;
      margin: 20px auto 40px;
      @include for-size(tablet) {
        width: 50%;
      }
      @include for-size(desktop) {
        width: 45%;
      }
    }

    &__btns_container {
      display: flex;
      flex-direction: column;
      @include for-size(desktop) {
        flex-direction: row;
      }
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    &__qr {
      width: 80%;
      margin: 0 auto;
      > span {
        margin-top: 70px;
      }
      > canvas {
        box-sizing: border-box;
        border: 10px white solid;
        @include for-size(tablet) {
          border: 20px white solid;
        }
      }
    }
  }

  &__mobile {
    @include for-size(desktop) {
      display: none;
    }
  }
  &__desktop {
    @include for-size(desktop, down) {
      display: none;
    }
  }

  &__loader {
    height: 50px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__btn {
    @include btn-style;
    @include for-size(desktop) {
      margin-right: 50px;
    }
  }

  &__btn__replay {
    @include btn-style;
    background: none;
    border-color: $white;
  }

  &__btn__share {
    @include btn-style;
    background: none;
    border-color: $white;
    @include for-size(desktop) {
      transform: none;
    }
  }

  &__modal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    &__title {
      color: $bright-blue;
      font-weight: 900;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      padding-bottom: 50px;
      @include for-size(desktop) {
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        letter-spacing: -0.5px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      padding: 90px 25px;
      margin: 0 20px;
      border-radius: 30px;
      position: relative;
      transform: translateY(-90%);

      @include for-size(tablet) {
        transform: translateY(-200%);
      }
      @include for-size(desktop) {
        transform: translateY(-350%);
      }

      &__close {
        position: absolute;
        right: 5%;
        top: 10%;
        & button {
          &:focus {
            outline: 0;
          }
        }
      }

      @include for-size(tablet) {
        padding: 80px 9vw;
      }

      input {
        margin: 30px 0 0;
        border: 0 none;
        outline: none;
        text-align: center;
        border-bottom: 1.5px solid #bfbfbf;

        @include for-size(tablet) {
          margin: 50px 0 0;
          font-size: 24px;
          line-height: 22px;
        }

        @include for-size(desktop) {
          margin: 65px 0 0;
        }

        &::placeholder {
          color: #bfbfbf;
          text-align: left;
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }
  }
}
