@import "../../utils/scss/index.scss";

$press-transition-time: 800ms;
$throw-transition-time: 800ms;

.tutorial {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // Super tiny screens
  @media all and (max-height: 600px) {
    min-height: 600px;
  }

  &__top {
    position: relative;
    &__text {
      &-a {
        display: block;
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 1.2rem;
      }

      &-b {
        font-size: 2rem;
      }
    }
    @include for-size(desktop) {
      width: 100%;
    }
  }

  &__main {
    height: 100%;
    width: 100%;
    &__character,
    &__result {
      position: relative;
      height: 194px;
      width: calc(90% - 14px);
      margin-left: 10%;
      @include for-size(phone, down) {
        @media all and (max-height: 700px) {
          height: 171px;
        }
        @media all and (max-height: 600px) {
          height: 135px;
        }
        @media all and (min-height: 701px) {
          height: 210px;
        }
      }
      @include for-size(tablet) {
        height: calc(260px * 1.05);
        width: calc(100% - 220px);
        margin-left: 124px;
        @media all and (orientation: landscape) {
          height: 220px;
        }
      }
      @include for-size(tablet-lg) {
        width: 452px;
        height: 335px;
        margin: 0;
        display: inline-block;
        @media all and (orientation: portrait) {
          width: 70%;
          height: 400px;
          margin-left: 28px;
        }
      }
      &-wrapper {
        height: 100%;
        &:focus {
          outline: none;
        }
      }
      &:focus {
        outline: none;
      }
      &__frame {
        &__back {
          border-radius: 15px;
          border: 2px solid $bright-blue;
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100% - 20px);
          height: 170px;
          @include for-size(phone, down) {
            @media all and (max-height: 700px) {
              height: 155px;
            }
            @media all and (max-height: 600px) {
              height: 121px;
            }
            @media all and (min-height: 701px) {
              height: 186px;
            }
          }
          @include for-size(tablet) {
            height: 260px;
            @media all and (orientation: landscape) {
              height: 186px;
            }
          }
          @include for-size(tablet-lg) {
            width: 437px;
            height: 317px;
            @media all and (orientation: portrait) {
              width: calc(100% - 28px);
              height: 372px;
            }
          }
        }
      }
      img {
        height: 100%;
        bottom: 2px;
        right: 0;
        object-fit: contain;
        object-position: center;
        position: absolute;
        transform-origin: bottom left;
        pointer-events: none;
        @include for-size(tablet) {
          transform: scale(1.1);
        }
        @include for-size(tablet-lg) {
          transform: scale(1);
        }
      }
      span {
        font-size: 1.3rem;
        position: absolute;
        top: 48px;
        left: 28px;
        color: white;
        text-shadow: 0px 2px 4px #313131;
        @include for-size(tablet) {
          top: 51px;
          left: 31px;
        }
      }
    }

    &__results-wrapper {
      text-align: center;
      
      picture {
        display: flex;
        justify-content: center;
        transform: translateY(5%);

        img { 
          max-height: 40vh;
        }

        @include for-size(tablet) {
          transform: translateY(20%);
        }

        @include for-size(desktop) {
          transform: translateY(10%);

          img { 
            width: 95%; 
            max-height: none;
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      height: 189px;
      position: relative;
      bottom: 0;
      left: 0;
      background: $bright-blue;
      box-sizing: border-box;
      @include for-size(tablet) {
        padding: 20px;
      }

      &__arrow-l {
        position: absolute;
        cursor: pointer;
        font-size: 25px;
        left: 6vw;
        bottom: 32px;
        z-index: 21;

        @include for-size(tablet) { left: 60px; }
        @include for-size(desktop) { left: 55px; }
      }
      &__arrow-r {
        position: absolute;
        cursor: pointer;
        z-index: 21;
        font-size: 25px;
        right: 6vw;
        bottom: 32px;

        @include for-size(tablet) { right: 60px; }
        @include for-size(desktop) { right: 55px; }
      }

      &__arrow-opacity {
        opacity: 0.5;
      }

      &__button {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $white;
        position: relative;
        padding: 0;
        top: 5px;
        @include for-size(tablet) {
          // margin-bottom: 23px;
        }
        &:after {
          content: "";
          height: 1px;
          background-color: $white;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      & > div {
        &:last-child {
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 100%;
          height: 50px;
        }
      }
    }

    &__slider {
      &__cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 259px);

        @include for-size(tablet) {
          height: calc(100% - 260px);
        }
        @include for-size(tablet-lg) {
          height: calc(100% - 189px);
        }
        @include for-size(desktop) {
          height: calc(100% - 305px);

          @media all and (min-height: 500px) {
            height: calc(100% - 190px);
          }

        }
        .slick-list {
          height: 100%;
          .slick-track {
            height: 100%;
          }
        }
        &:focus {
          outline: none;
        }
        & > div {
          height: 100% !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          & > div {
            & > div {
              .tutorial__main__character {
                @include for-size(tablet-lg) {
                  margin-top: 30px;
                }
                & + .tutorial__main__character {
                  @include for-size(tablet) {
                    margin-top: 30px;
                    @media all and (orientation: landscape) {
                      margin-top: 0;
                    }
                  }
                  @include for-size(tablet-lg) {
                    margin-left: 30px;
                  }
                }
              }
              &:nth-child(2) {
                .tutorial__main__character {
                  &:first-child {
                    div {
                      background: $bright-blue;
                    }
                  }
                  &:last-child {
                    div {
                      background: rgba(0, 0, 0, 0.25);
                    }
                  }
                }
              }

              .tutorial__main__result {
                @include for-size(tablet-lg) {
                  margin-top: 30px;
                }
                & + .tutorial__main__character {
                  @include for-size(tablet) {
                    margin-top: 30px;
                    @media all and (orientation: landscape) {
                      margin-top: 0;
                    }
                  }
                  @include for-size(tablet-lg) {
                    margin-left: 30px;
                  }
                }
              }
            }
          }
        }
      }

      &__texts {
        width: 100%;
        position: absolute;
        top: -5px;
        left: 0;
        @include for-size(tablet) {
          top: 5px;
        }
        &-label {
          &:focus {
            outline: none;
          }
          max-width: 320px;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 31px;
          text-align: center;
          letter-spacing: -0.5px;
          color: $white;
          padding-top: 19px;
          @include for-size(tablet) {
            max-width: 100%;
            font-size: 23px;
            line-height: 31px;
          }
        }
        & > div {
          & + ul {
            @include for-size(tablet) {
              bottom: -40px;
            }
            li {
              button {
                &::before {
                  font-size: 10px;
                  padding: 0;
                  color: #bfbfbf;
                  opacity: 1;
                }
              }
              &.slick-active {
                button {
                  &::before {
                    color: $white !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__footer {
      width: 100%;
      padding-bottom: 20px;
      margin: 0 auto;
      margin-bottom: 5px;
      line-height: 0.9rem;
      background: $light-blue;

      &__hide {
        display: none;
      }

      &__goto {
        font-size: 12px !important;
        padding-bottom: 20px;
        color: #CCC !important;
        text-decoration: underline;
      }
      span {
        font-size: 12px;
        line-height: 16px;
        display: block;
        color: #CCC;
      }
      strong {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      &__links {
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: 12px;
          display: block;
          padding-right: 10px;
          line-height: 16px;
          color: #CCC;
        }
      }
      a {
        font-size: 12px;
        display: block;
        padding-right: 10px;
        line-height: 16px;
        color: #CCC;
      }
      @include for-size(desktop) {
        width: 100%;
        padding: 20px;
        bottom: 55px;
        left: 55px;
        
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 1.2rem;
          letter-spacing: -0.5px;
        }
        &__goto {
          margin-bottom: 107px;
          padding-bottom: 0;
          display: block;
          width: 238px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.13rem;
          line-height: 1.4rem;
          text-align: center;
          letter-spacing: 0.5px;
          text-decoration: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 190px;
            background-color: #c4c4c4;
            left: 24px;
            bottom: -2px;
          }

          & + span {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  &__loader {
    height: 50px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__begin-btn {
    color: $white;
    font-size: 1.5rem;
    line-height: 1rem;
    position: relative;
    bottom: 10px;
    padding: 15px 0;
    width: 70%;
    border-radius: 25px;
    border: 2px solid $white;
    display: block;
    width: 245px;
    margin: 0 auto;
    @include for-size(phone, down) {
      margin-bottom: -20px;
    }
  }
}
