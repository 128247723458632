$primary-font: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

@font-face {
  font-family: "Avenir";
  src: local("Avenir Medium"), local("Avenir-Medium"),
    url("../../static/fonts/Avenir-Medium.woff2") format("woff2"),
    url("../../static/fonts/Avenir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
