@import "~normalize.css"; /* bring in normalize.css styles */
@import "./utils/scss/index.scss";

html {
  height: 100%;
  min-height: 100%;
}
body {
  height: 100%;
  width: 100%;
  background-color: $black;
  color: white;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $dark-blue;
  background: linear-gradient($dark-blue, $light-blue);
  background-size: auto 100%;
}

:global {
  .full-height {
    height: 100% !important;
  }
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  width: 100%;
  > div {
    height: inherit;
    width: inherit;
  }
}

a {
  text-decoration: none;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
  background: none;
  border: none;
}

*:not(input) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
