@import "../../utils/scss/index.scss";

.landing {
  position: relative;
  height: 100%;
  // min-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient($dark-blue, $light-blue);

  @media all and (max-width: 400px) {
    min-height: 850px;
  }

  @media all and (max-width: 500px) {
    min-height: 1050px;
  }

  @media all and (min-width: 500px) {
    min-height: 1200px;
  }

  @include for-size(desktop) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    align-items: flex-start;
    min-height: 750px;
  }

  &__top {
    position: relative;
    width: 80%;
    z-index: 1;
    &__text {
      &-a {
        display: block;
        padding-bottom: 6px;
        font-size: 1.2rem;

        @include for-size(desktop) {
          font-style: normal;
          font-weight: 800;
          font-size: 2.5rem;
          line-height: 1.5rem;
          letter-spacing: -0.5px;
          padding-top: 53px;
          padding-left: 55px;
        }
      }

      &-b {
        font-size: 2rem;
        display: block;
        padding-bottom: 10px;

        @include for-size(desktop) {
          font-style: normal;
          font-weight: 800;
          font-size: 4.625rem;
          line-height: 7.5rem;
          letter-spacing: -0.5px;
          padding-left: 55px;
          margin-top: -11px;
          padding-bottom: 0;
        }
        @include for-size(1440px) {
          font-size: 5.625rem;
        }
      }
      &-c {
        font-size: 5vw;
        display: block;
        margin-top: 20px;

        br {
          display: none;
        }

        @include for-size(desktop) {
          font-style: normal;
          font-weight: 500;
          font-size: 1.625rem;
          line-height: 1.9375rem;
          letter-spacing: -0.5px;
          padding-left: 55px;
          display: block;
          margin-top: 50px;

          br {
            display: block;
          }
        }
      }
    }
    @include for-size(desktop) {
      width: 55%;
      text-align: left;
    }
  }
  &__mid {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 100%;
    z-index: 1;
    @include for-size(desktop) {
      width: 50%;
      text-align: center;
      justify-content: start;
      padding-bottom: 60px;
    }
    // @include for-size(desktop-lg) {
    //   padding-bottom: 100px;
    // }
  }
  &__bottom {
    position: relative;
    @include for-size(desktop) {
      position: absolute;
      bottom: 0;
      width: calc(50% - 55px);
      padding-left: 55px;
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 30px 0;
    overflow: hidden;

    @include for-size(desktop) {
      align-self: center;
      width: 45%;
      transform: scale(1.2);
    }

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      &__animation {
        width: 100%;
        // max-width: 400px;
        pointer-events: none;

        // @media all and (min-width: 500px) {
        //   max-width: none;
        //   width: 80%;
        //   transform: translateY(-10%) scale(0.8);
        // }

        @include for-size(tablet) {
          width: 90%;

          @media all and (max-height: 800px) {
            width: 80%;
          }
        }

        @include for-size(desktop) {
          transform: translateX(-5%) scale(1.2);
        }

        > div {
          // svg {
          //   & > g {
          //     transform: translateX(15%) scale(1);
          //   }

          //   clipPath:first-child {
          //     rect { transform: translateX(-15%); }
          //     & > g { transform: translateX(15%); }
          //   }
          // }

          // @media all and (min-width: 501px) {
          //   svg {
          //     & > g { transform: translateX(8%) translateY(5%) scale(0.9); }

          //     clipPath:first-child {
          //       rect { transform: translateX(0%); }
          //       & > g { transform: translateX(10%); }
          //     }
          //   }
          // }

          // @include for-size(tablet) {
          //   svg {
          //     & > g { transform: translateX(4%) translateY(19%) scale(0.9); }

          //     clipPath:first-child {
          //       rect { transform: translateX(0%); }
          //       & > g { transform: translateX(15%); }
          //     }
          //   }

          //   @media all and (max-height: 800px) {
          //     svg {
          //       & > g { transform: translateX(15%) translateY(15%) scale(0.7); }
          //     }
          //   }
          // }

          @include for-size(desktop) {
            svg {
              & > g {
                transform: translateX(5%) translateY(0) scale(1);
              }
            }
          }
        }
      }
    }

    &__footer {
      width: 90%;
      padding-bottom: 20px;
      margin: 0 auto;
      margin-bottom: 5px;
      line-height: 0.9rem;
      &__goto {
        font-size: 1.2rem !important;
        line-height: 1.7rem !important;
        padding-bottom: 20px;
        color: #c4c4c4 !important;
        text-decoration: underline;
        @include for-size(desktop) {
          max-width: 260px;
        }
      }
      span {
        font-size: 12px;
        line-height: 16px;
        display: block;
        color: #ccc;
      }
      strong {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        margin-bottom: 20px;
      }
      &__links {
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: 12px;
          display: block;
          padding-right: 10px;
          line-height: 16px;
          color: #ccc;
        }
      }
      a {
        font-size: 12px;
        display: block;
        padding-right: 10px;
        line-height: 18px;
        color: #ccc;
      }
      @include for-size(desktop) {
        width: 100%;
        // position: fixed;
        bottom: 55px;
        left: 55px;
        text-align: left;
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.5px;
        }

        &__goto {
          margin-bottom: 107px;
          padding-bottom: 0;
          display: block;
          width: 35%;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.13rem;
          line-height: 1.4rem;
          text-align: center;
          letter-spacing: 0.5px;
          position: relative;
        }

        & + span {
          font-size: 0.8rem;
          line-height: 1.5rem;
        }

        &__links {
          text-align: left;
        }
      }
    }
  }

  &__loader {
    height: 50px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__begin-btn {
    color: white !important;
    font-size: 1.3rem !important;
    position: relative;
    max-width: 260px;
    background-color: $bright-blue;
    padding: 15px 0;
    width: 70%;
    border-radius: 25px;
  }

  &__modal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: 20;

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }

    &__input__container {
      margin: 10px 0 0;
      display: flex;
      flex-direction: column;

      @include for-size(tablet) {
        margin: 10px 0 0;
        font-size: 24px;
        line-height: 22px;
      }

      @include for-size(desktop) {
        margin: 45px 0 0;
      }

      &__error_message_hide {
        color: $red;
        text-align: left;
        font-size: 15px;
        opacity: 0;
      }

      &__error_message_show {
        color: $red;
        text-align: left;
        font-size: 15px;
        opacity: 1;
      }
    }

    &__question {
      color: $bright-blue;
      font-weight: 900;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      padding: 30px 25px;
      margin: 0 30px;
      border-radius: 30px;

      @include for-size(tablet) {
        padding: 35px 65px;
      }

      input {
        border: 0 none;
        outline: none;
        text-align: center;
        border-bottom: 1.5px solid #bfbfbf;

        @include for-size(tablet) {
          font-size: 24px;
          line-height: 22px;
        }

        &::placeholder {
          color: #bfbfbf;
          text-align: left;
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }

      &__input_error {
        border-bottom: 1.5px solid $red !important;
      }
    }
  }

  &__btn {
    margin: 30px 0 0;
    height: 47px;
    width: 243px;
    border: 1.5px solid #bfbfbf;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bfbfbf;

    @include for-size(tablet) {
      margin: 50px 0 0;
    }

    @include for-size(desktop) {
      margin: 65px 0 0;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
