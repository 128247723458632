@import "../../utils/scss/index.scss";

.carousel.carousel-slider .control-arrow:hover {
  background-color: transparent;
}

.carousel .slide {
  background: transparent;
  padding: 0 5%;
  padding-bottom: 40px;
}

.slider {
  &:first-child {
    // padding-left: 5%;
  }
}
