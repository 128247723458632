@import "../../utils/scss/index.scss";

.onboarding {
  position: relative;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  min-height: 850px;
  background: linear-gradient($dark-blue, $light-blue);
  background-size: auto 100%;

  @include for-size(tablet) {
    height: 100%;
    min-height: 500px;
  }

  @include for-size(desktop) {
    height: 100%;
    min-height: 850px;

    align-items: start;
    align-items: flex-start;
  }

  &__top {
    position: relative;
    margin-top: 10px;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include for-size(desktop) {
      width: 100%;
    }

    &__text {
      &-a {
        display: block;
        position: relative;
        z-index: 2;
        padding-bottom: 10px;
        font-size: 1.625rem;
        line-height: 2rem;
        @include for-size(tablet) {
          font-size: 2.8125rem;
          line-height: 5.1875rem;
        }
        @include for-size(desktop) {
          max-width: 55vw;
          font-style: normal;
          font-weight: 800;
          font-size: 4.75rem;
          letter-spacing: -0.5px;
          padding-top: 53px;
          padding-left: 55px;
          align-self: flex-start;
          text-align: left;
          white-space: nowrap;
        }
      }
    }

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 95%;
      @include for-size(desktop) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        align-items: flex-start;
      }
      &__animation {
        height: 80%;
        width: 100%;
        pointer-events: none;

        // @include for-size(500px, down) {
        //   transform: scale(0.8) translateY(-10%);
        // }

        @include for-size(desktop) {
          width: 50%;
          top: 50%;
          height: 40vw;
          transform: translate(5vw, -20%) scale(1.2);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @include for-size(1500px, up) {
          transform: translate(-5vw, -20%) scale(1.4);
        }

        > div {
          position: absolute;
          width: 100%;
          // @include for-size(500px, down) {
          //   transform: translateX(3%);
          //   svg {
          //     clipPath:first-child {
          //       rect {
          //         transform: translateX(-70px);
          //         -ms-transform: translateX(-70px); /* IE 9 */
          //         -webkit-transform: translateX(-70px); /* Safari and Chrome */
          //         -o-transform: translateX(-70px); /* Opera */
          //         -moz-transform: translateX(-70px); /* Firefox */
          //       }
          //     }
          //     & > g {
          //       transform: translateX(70px);
          //       -ms-transform: translateX(70px); /* IE 9 */
          //       -webkit-transform: translateX(70px); /* Safari and Chrome */
          //       -o-transform: translateX(70px); /* Opera */
          //       -moz-transform: translateX(70px); /* Firefox */
          //     }
          //   }
          //   @media all and (max-height: 600px) {
          //     width: 110%;
          //     transform: translateX(-1%);
          //   }
          //   @media all and (min-height: 601px) {
          //     width: 135%;
          //     transform: translateX(-10%);
          //   }
          //   @media all and (max-height: 400px) {
          //     width: 70%;
          //     transform: translateX(23%);
          //   }
          //   @media all and (min-height: 800px) {
          //     width: 145%;
          //     transform: translateX(-12%);
          //   }
          // }
          @include for-size(501px, up) {
            width: 100%;
            height: 100%;
            transform: translateY(-12%);
            @media all and (orientation: landscape) {
              width: 60%;
              transform: translate(35%, -16%);
            }

            @media all and (max-height: 600px) {
              height: 103%;
            }
          }
          @include for-size(desktop) {
            width: 100%;
            transform: none;
          }
        }
      }
    }

    &__image {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &__footer {
      width: 90%;
      padding-bottom: 20px;
      margin: 0 auto;
      margin-bottom: 5px;
      line-height: 0.9rem;
      &__goto {
        font-size: 1.2rem !important;
        padding-bottom: 20px;
        color: #c4c4c4 !important;
        text-decoration: underline;
      }
      span {
        font-size: 12px;
        line-height: 16px;
        display: block;
        color: #ccc;
      }
      strong {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        margin-bottom: 20px;
      }
      &__links {
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: 12px;
          display: block;
          padding-right: 10px;
          line-height: 16px;
          color: #ccc;
        }
      }
      a {
        font-size: 12px;
        display: block;
        padding-right: 10px;
        line-height: 16px;
        color: #ccc;
      }
      @include for-size(desktop) {
        width: 100%;
        position: absolute;
        bottom: 55px;
        left: 55px;
        text-align: left;
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 1.2rem;
          letter-spacing: -0.5px;
        }
        &__goto {
          margin-bottom: 107px;
          padding-bottom: 0;
          display: block;
          width: 238px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.13rem;
          line-height: 1.4rem;
          text-align: center;
          letter-spacing: 0.5px;
          text-decoration: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 190px;
            background-color: #c4c4c4;
            left: 24px;
            bottom: -2px;
          }

          & + span {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
        &__links {
          text-align: left;
        }
      }
    }
  }

  &__loader {
    height: 50px;
    position: absolute;
    transform: translateY(-50%);
  }
  form {
    width: 100%;
    position: relative;
    max-width: 296px;
    @include for-size(desktop) {
      width: 50%;
      text-align: left;
      padding-left: 55px;
      box-sizing: border-box;
      max-width: 583px;
    }
  }
  &__input {
    color: white;
    font-size: 1.25rem;
    line-height: 1.375rem;
    text-align: left;
    // text-transform: capitalize;
    position: relative;
    bottom: 10px;
    background-color: transparent;
    padding: 18px 0;
    width: 100%;
    max-width: 296px;
    border: none;
    border-radius: none;
    border-bottom: 1.5px solid $bright-blue;
    &::placeholder {
      color: white;
      text-align: left;
    }
    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    @include for-size(desktop) {
      max-width: 583px;
      font-size: 2.5rem;
      padding: 22px 0;
      margin-top: 40px;
      text-indent: 26px;
    }
    &-ready {
      position: absolute;
      right: 0;
      top: 0;
      background-color: $bright-blue;
      height: 42px;
      width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 50%;
      @include for-size(desktop) {
        display: none;
      }
    }
    &-continue {
      color: $white;
      font-style: normal;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: center;
      letter-spacing: 0.5px;
      border: 2px solid $white;
      border-radius: 30px;
      width: 243px;
      height: 47px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 46px;
      @include for-size(tablet-lg, down) {
        display: none;
      }
      &.ready {
        background: $bright-blue;
        border-color: $bright-blue;
      }
    }
  }

  &__submitted {
    background-color: $bright-blue;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    @include for-size(desktop) {
      margin-left: calc(25% - 30px);
      margin-top: 5%;
    }
  }
}
