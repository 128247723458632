@import "../../utils/scss/index.scss";

.analyzing {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 800px;
  background: linear-gradient($dark-blue, $light-blue);
  background-size: auto 100%;

  @include for-size(desktop) {
    min-height: 800px;
  }

  &__top {
    position: relative;
    // height: 15%;
    @include for-size(desktop) {
      width: 100%;
      text-align: left;
      & > div {
        width: 245px;
        display: inline-block;
      }
    }
    &__text {
      &-a {
        display: block;
        padding-bottom: 10px;
        font-size: 1.2rem;
        @include for-size(desktop) {
          font-size: 1.875rem;
          line-height: 1.9rem;
          display: inline;
          padding-right: 7px;
          top: -38px;
          position: relative;
          padding-left: 25px;
        }
      }

      &-b {
        font-size: 1.2rem;
        @include for-size(desktop) {
          font-size: 1.875rem;
          line-height: 1.9rem;
          top: -38px;
          position: relative;
        }
      }
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 90%;

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // border: 1px solid white;
      &__animation {
        height: 80%;
        width: 100%;
        position: relative;
        transform: translateY(0);
        pointer-events: none;
        @include for-size(850px, down) {
          @media all and (orientation: landscape) {
            transform: none;
            height: 100%;
          }
        }
        @include for-size(desktop) {
          width: 100%;
          height: 100%;
          transform: translateY(-3%);
        }
        > div {
          position: absolute;
          height: 100%;
          width: 100%;
        }
      }
    }

    &__image {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
      // transform: scale(1.2) translateY(-20%);
    }
    &__footer {
      width: 80%;
      padding-bottom: 20px;
      margin: 0 auto;
      margin-bottom: 5px;
      margin-top: 30px;
      line-height: 0.9rem;
      &__goto {
        font-size: 1.2rem !important;
        padding-bottom: 20px;
        color: #c4c4c4 !important;
        text-decoration: underline;
      }
      span {
        font-size: 12px;
        line-height: 16px;
        display: block;
        color: #CCC;
      }
      strong {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        margin-bottom: 20px;
      }
      &__links {
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: 12px;
          display: block;
          padding-right: 10px;
          line-height: 16px;
          color: #CCC;
        }
      }
      a {
        font-size: 12px;
        display: block;
        padding-right: 10px;
        line-height: 16px;
        color: #CCC;
      }
      @include for-size(desktop) {
        width: 100%;
        // position: fixed;
        bottom: 55px;
        left: 55px;

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 1.2rem;
          letter-spacing: -0.5px;
        }
        &__goto {
          margin-bottom: 107px;
          padding-bottom: 0;
          display: block;
          width: 238px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.13rem;
          line-height: 1.4rem;
          text-align: center;
          letter-spacing: 0.5px;
          text-decoration: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 190px;
            background-color: #c4c4c4;
            left: 24px;
            bottom: -2px;
          }

          & + span {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
        &__links {
          text-align: center;
        }
      }
    }
  }

  &__loader {
    height: 50px;
    position: absolute;
    transform: translateY(-50%);
  }
}
