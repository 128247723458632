@import "../../utils/scss/index.scss";

$press-transition-time: 400ms;
$throw-transition-time: 600ms;

.picker {
  height: calc(100% + 280px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background: radial-gradient(ellipse at top, $light-blue 10%, $dark-blue 100%);
  @include for-size(tablet) {
    height: 100%;
  }
  @include for-size(desktop) {
    height: calc(100% + 100px);
  }
  &__top {
    pointer-events: none;
    position: relative;

    &__header {
      @include for-size(tablet) {
        padding-left: 0;
        align-items: center;
      }
    }

    &__text {
      &-a {
        display: block;
        padding-bottom: 10px;
        font-size: 1.2rem;
        @include for-size(tablet) {
          font-style: normal;
          font-weight: 800;
          font-size: 2.8rem;
          line-height: 1.3rem;
          text-align: center;
          letter-spacing: -0.5px;
          margin: 36px 0;
        }
      }

      &-b {
        font-size: 2rem;
      }
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 90%;

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 95%;
      &.bg {
        position: absolute;
        .picker__main__character__frame {
          pointer-events: none;
          box-sizing: border-box;
        }
      }
      @include for-size(tablet) {
        @media all and (orientation: landscape) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      @include for-size(desktop) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__character {
      position: relative;
      height: 50%;
      color: white;
      // border: 1px white solid;
      border-radius: 5px;
      width: 100%;
      @include for-size(tablet) {
        width: 80%;
        @media all and (orientation: landscape) {
          width: 48%;
          height: 60%;
        }
      }
      @include for-size(desktop) {
        width: 48.5%;
        height: 75%;
      }

      &__frame {
        // display: flex;
        padding: 0;
        border: 2px solid $bright-blue;
        height: 90%;
        width: 100%;
        border-radius: 20px;
        position: relative;
        // transform: translateX(-2px);
        outline: none;
        // Touch-tap highlights on touch devices.
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; // i.e. Nexus5/Chrome and Kindle Fire HD 7''
        transform-origin: bottom right;

        &__back {
          position: absolute;
          border: 2px solid $bright-blue;
          height: 90%;
          width: 100%;
          border-radius: 20px;
          box-sizing: border-box;
        }

        &__inner {
          pointer-events: none;
          border-radius: 15px;
          // position: relative;
          position: absolute;
          top: 0;
          overflow: hidden;
          height: 100%;
          width: 100%;
          outline: none;
          // This solves an annoing Safari bug, do not remove.
          -webkit-mask-image: -webkit-radial-gradient(white, black);

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            // bottom: 0; // Do not add this.
            border-radius: 15px;
            border: 2px solid $bright-blue;
            background-color: $bright-blue;
            transform: scaleY(0);
            transform-origin: bottom;
            transition: transform $press-transition-time ease-in-out;
          }
        }

        &.pressed {
          animation-name: throw;
          animation-delay: $press-transition-time;
          animation-duration: $throw-transition-time;
          animation-iteration-count: 1;
          animation-timing-function: ease-out;
          animation-direction: alternate;
          animation-fill-mode: forwards;
          transform-origin: bottom right;
          .picker__main__character__frame__inner {
            &:before {
              content: "";
              transform: scaleY(1);
            }
          }
          &.a {
            @include for-size(tablet) {
              animation-name: throw-left;
              transform-origin: bottom left;
            }
          }
        }
        &.blocked {
          pointer-events: none;
        }
      }

      img {
        height: 100%;
        bottom: 0;
        right: 0;
        // width: 100%;
        object-fit: contain;
        object-position: center;
        position: absolute;
        transform: scale(1.1);
        // border: 1px solid;
        transform-origin: bottom left;
        pointer-events: none;
      }

      span {
        font-size: 1.3rem;
        position: absolute;
        top: 20px;
        left: 20px;
        color: white;
        text-shadow: 0px 2px 4px #313131;
        pointer-events: none;
      }
    }

    &__footer {
      width: 80%;
      padding-bottom: 20px;
      margin: 0 auto;
      margin-bottom: 5px;
      margin-top: 30px;
      line-height: 0.9rem;
      &__goto {
        font-size: 1.2rem !important;
        padding-bottom: 20px;
        color: #c4c4c4 !important;
        text-decoration: underline;
      }
      span {
        font-size: 12px;
        line-height: 16px;
        display: block;
        color: #CCC;
      }
      strong {
        font-size: 0.9rem;
        line-height: 1.2rem;
        display: inline-block;
        margin-bottom: 20px;
      }
      &__links {
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: 12px;
          display: block;
          padding-right: 10px;
          line-height: 16px;
          color: #CCC;
        }
      }
      a {
        font-size: 12px;
        display: block;
        padding-right: 10px;
        line-height: 16px;
        color: #CCC;
      }
      @include for-size(desktop) {
        width: 100%;
        // position: fixed;
        bottom: 55px;
        left: 55px;

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 1.2rem;
          letter-spacing: -0.5px;
        }
        &__goto {
          margin-bottom: 107px;
          padding-bottom: 0;
          display: block;
          width: 238px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.13rem;
          line-height: 1.4rem;
          text-align: center;
          letter-spacing: 0.5px;
          text-decoration: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 190px;
            background-color: #c4c4c4;
            left: 24px;
            bottom: -2px;
          }

          & + span {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
        &__links {
          text-align: center;
        }
      }
    }
  }

  &__loader {
    text-align: center;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__bottom {
    height: 10%;
    width: 100%;
    position: relative;
    bottom: 0;

    & > div {
      position: absolute;
      width: 100%;
      bottom: 0;
      // margin: 0 auto;
      // transform: scale(1.2);
    }
  }

  &__begin-btn {
    color: white;
    position: relative;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    background-color: #3a84f5;
    padding: 10px 40px;
    border-radius: 3px;
    text-shadow: 0px 2px 4px #313131;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
  }
}

@keyframes throw {
  from {
    pointer-events: none;
    opacity: 1;
    transform: translate(0, 0) rotate(0deg);
  }

  to {
    pointer-events: none;
    transform: translate(20%, -15%) rotate(120deg);
    opacity: 1;
    // transform: translate(0%, 0%) rotate(12deg);
  }
}

@keyframes throw-left {
  from {
    pointer-events: none;
    opacity: 1;
    transform: translate(0, 0) rotate(0deg);
  }

  to {
    pointer-events: none;
    transform: translate(-20%, -15%) rotate(-120deg);
    opacity: 1;
    // transform: translate(0%, 0%) rotate(12deg);
  }
}

// character specific customizations
// names in lowercase, replace spaces for "-".
.hulk {
  transform: scale(1.2) !important;
}

.matter {
  transform: scale(1.2) !important;
}
