@import "../../utils/scss/index.scss";

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  @include for-size(desktop) {
    align-items: flex-start;
    padding-top: 67px;
    padding-left: 55px;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    &__logo {
      height: 80px;
      @include for-size(desktop) {
        width: 245px;
        height: auto;
      }
    }
  }

  span {
    text-align: center;
    max-width: 80%;
  }
}
